import { setPagePersistenceLevel, persistPagePreferences } from "./preferences/PreferencePersistence.mjs"
import { loadPagePreferences } from "./preferences/PreferenceReference.mjs"

function configureKeybinds() {
    document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && !e.shiftKey && !e.metaKey && e.altKey && e.key === "s") {
            e.preventDefault()
            e.stopPropagation()
            import("debug").then((debugModule) => debugModule.showExportOfCurrentPost())
                .catch((ex) => console.error("Failed to load debug module and show export of current post: ", ex))
        }
    })
}

function onDOMLoaded() {
    setPagePersistenceLevel()
    configureKeybinds()
    loadPagePreferences()
    persistPagePreferences()
    if (document.getElementById("preferenceList")) {
        document.documentElement.dataset.persistenceDisplay = "loading"
        import("persistence").then((persistenceModule) => persistenceModule.showPersistedData())
            .catch((ex) => {
                console.error("Failed to load persistence module and show persisted data: ", ex)
                delete document.documentElement.dataset.persistenceDisplay
            })
    }
    if (document.getElementById("interactiveBody")) {
        import("interactive").then((interactiveModule) => interactiveModule.initInk())
            .catch((ex) => {
                console.error("Failed to load interactive module:", ex)
            })
    }
}

onDOMLoaded()